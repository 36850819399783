export const headerData = [
   // {
   //    "id": "bf-deals",
   //    "className": "", 
   //    "title": "BLACK FRIDAY DEALS",
   //    "desc": "2024 Black Friday Tech Deals.",
   //    "img": require("../../imgs/Affiliates/bf-deals.png"),
   //    "url": "https://sulce.notion.site/Black-Friday-Tech-Deals-2024-1495e2c3d17080f9a03ee9a034ed7d45",
   //    "type": "highlight"
   // },
   {
      "id": "amazon-store-front-button",
      "className": "", 
      "title": "AMAZON STORE FRONT",
      "desc": "All My Amazon Products.",
      "img": require("../../imgs/Affiliates/amazon-logo.jpg"),
      "url": "https://www.amazon.com/shop/sulce",
      "type": "highlight"
   },
   {
      "id": "3d-prints-button",
      "className": "", 
      "title": "3D PRINTS",
      "desc": "All My 3D Print Files.",
      "img": require("../../imgs/Affiliates/3d-print.png"),
      "url": "https://www.thingiverse.com/sulcetech/collections/38446694/things",
      "type": "highlight"
   },
   {
      "id": "wallpapers-button",
      "className": "", 
      "title": "WALLPAPERS",
      "desc": "All My Wallpapers.",
      "img": require("../../imgs/Wallpapers/Stellar.png"),
      "url": "https://sulce.gumroad.com",
      "type": "highlight"
   },
]