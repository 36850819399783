import React from 'react'
import { motion } from "framer-motion"
import { FaDiscord } from "react-icons/fa"; // Font Awesome 

import '../styles/MainBody.scss';
import { LatestYoutubeVideo, Products, MyProducts, Wallpapers, Giveaways, Affiliates, SocialIcon, DeskMats, BuyMeACoffee, KeyboardTester } from './index.js';

export const MainBody = ({productsData, myProductsData, wallpapersData, giveawayData, affiliateData, deskmatData, headerData }) => {
  function isMobile() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  }
  
  return (
    <div id='main-body'>
      <motion.div id="latest-video-container" className=""
        initial={{y: '50vh'}}
        animate={{y: '0'}}
        exit={{y: '50vh'}}
        transition={{type: "spring",stiffness: 30}}
      >
        <h3>Latest Video</h3>
        <LatestYoutubeVideo channelId="UU8JIN_PpxuQs5nzy3HEsZ0w" />
      </motion.div>
      <motion.div
        className='coffee-and-keyboard'
        initial={{y: '100vw'}}
        animate={{y: '0'}}
        exit={{x: '-100vw'}}
        transition={{type: "spring",stiffness: 30}}
      >
        <BuyMeACoffee />
        {(!isMobile()) ? <KeyboardTester /> : "" }
      </motion.div>
      <motion.div id='giveaways-container'
        initial={{y: '100vw'}}
        animate={{y: '0'}}
        exit={{x: '-100vw'}}
        transition={{type: "spring",stiffness: 30}}
      >
        <div id='giveaways-cont'>
          <Giveaways giveawayData={giveawayData} />
        </div>
      </motion.div>
      <motion.div className="sub-header"
        initial={{y: '100vw'}}
        animate={{y: '0'}}
        exit={{x: '-100vw'}}
        transition={{type: "spring",stiffness: 30}}
      >
        <Affiliates affiliateData={headerData} type={"headerData"}/>
        {/* <ExternalLink id="amazon-store-front-button" url="https://www.amazon.com/shop/sulce" text="Amazon StoreFront" />
        <ExternalLink id="print-button" url="https://www.thingiverse.com/sulcetech/collections/38446694/things" text="3D PRINTS" />
        <ExternalLink id="wallpapers-button" url="https://sulce.gumroad.com" text="WALLPAPERS" /> */}
      </motion.div>
      <motion.div id='product-wallpaper-container'
        initial={{y: '100vw'}}
        animate={{y: '0'}}
        exit={{x: '-100vw'}}
        transition={{type: "spring",stiffness: 30}}
      >
        <div id='product-cont'>
          <Products productsData={productsData} />
        </div>
        <motion.div id='deskmat-container'
          initial={{y: '100vw'}}
          animate={{y: '0'}}
          exit={{x: '-100vw'}}
          transition={{type: "spring",stiffness: 30}}
        >
          <div id='deskmat-cont'>
            <DeskMats deskmatData={deskmatData}/>
          </div>
        </motion.div>
        <div id='my-product-cont'>
          <MyProducts myProductsData={myProductsData} />
        </div>
        <div id='wallpaper-cont'>
          <Wallpapers wallpapersData={wallpapersData} />
        </div>
      </motion.div>

      <motion.div id='discord-container'
        initial={{y: '100vw'}}
        animate={{y: '0'}}
        exit={{x: '-100vw'}}
        transition={{type: "spring",stiffness: 30}}
      >
        <div id='discord-cont'>
          <a id="discord" 
            href="https://discord.gg/tz5PBBrPGe" 
            target='_blank' 
            rel='noreferrer'>
              <div className='d-flex flex-row sub-header discord'>
                <h3>Join Our Discord</h3>
                <SocialIcon
                  id={'Discord'} 
                  className={''}
                  icon={<FaDiscord />}
                  url={'https://discord.gg/tz5PBBrPGe'}
                  checkLink={false}  
                />
              </div>
          </a>
        </div>
      </motion.div>
      <motion.div id='affiliates-container'
        initial={{y: '100vw'}}
        animate={{y: '0'}}
        exit={{x: '-100vw'}}
        transition={{type: "spring",stiffness: 30}}
      >
        <div id='affiliates-cont'>
          <Affiliates affiliateData={affiliateData} />
        </div>
      </motion.div>
    </div>
  )
}

// export default Body
