import {React, useState} from 'react'
import { motion } from "framer-motion"
import Modal from 'react-modal';

import '../styles/DeskMats.scss';
import { DeskMatCard } from './index.js';
import { FaArrowRight, FaTimes } from "react-icons/fa";


export const DeskMats = ({deskmatData}) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  let deskmatSearchCount = 0;

  let DeskMatsList = deskmatData.map((element, id) => {
    if(element.type.toLowerCase().includes('highlight')) {
      return <DeskMatCard
          key={element.id}
          id={element.id}
          title={element.title}
          desc={element.desc}
          className={element.className}
          img={element.img}
          links={element.links}
          videoPreview={element.preview}
      />
    } else {
      return <></>
    }
  })

  let tempFullDeskMatsList = deskmatData.map((element, id) => {
        return <DeskMatCard
         key={element.id}
          id={element.id}
          title={element.title}
          desc={element.desc}
          className={element.className}
          img={element.img}
          links={element.links}
          type={element.type}
          videoPreview={element.preview}
         />

  })
  const [FullDeskMatsList, setFullDeskMatsList] = useState(tempFullDeskMatsList);
  const [StaticFullDeskMatsList] = useState(tempFullDeskMatsList)
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: 'var(--black)'
    },
  };

  function openModal() {
    setIsOpen(true);
    setFullDeskMatsList(StaticFullDeskMatsList)
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // console.log("console open")
  }

  function closeModal() {
    setIsOpen(false);
  }

  const handleDeskMatSearch = (e) => {
    deskmatSearchCount = 0
    tempFullDeskMatsList = deskmatData.map((element, id) => {
      if(element.title.toLowerCase().includes(e.target.value.toLowerCase())
      || element.desc.toLowerCase().includes(e.target.value.toLowerCase())
      ) {
        deskmatSearchCount++;
        return <DeskMatCard
            key={element.id}
            id={element.id}
            title={element.title}
            desc={element.desc}
            className={element.className}
            img={element.img} 
            links={element.links}
            type={element.type}
            videoPreview={element.preview}
          />
      } else {
        return <></>
      }
    })
    if(deskmatSearchCount === 0) {
      tempFullDeskMatsList = <div className="w-100 h-100 d-flex align-items-center justify-content-center"><p>No Results Found</p></div>
    }

    setFullDeskMatsList(tempFullDeskMatsList)
  }


   return (
    <div id="deskmats">
      <a id="deskmat" 
            href="https://sulce.shop" 
            target='_blank' 
            rel='noreferrer'>
              <div className='d-flex flex-row desk-mat'>
                <h3>SULCE MATS</h3>
              </div>
          </a>
      <div className="deskmats-container">
      {DeskMatsList}
      <div></div>
      </div>
      <div className="w-100 p-2 d-flex justify-content-end">
        <div className="open-modal-text" onClick={openModal}>
          View All Deskmats  <FaArrowRight id="FaArrowRight"/>
        </div>
      </div>
    <div>
      <Modal
        id='deskmat-modal'
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Full Deskmats List Modal"
        ariaHideApp={false}
      >
        <div className="deskmat-modal-header w-100 d-flex justify-content-between">
          <div id="deskmat-search-container" className='d-flex flex-row w-75'>
            <h2>All Deskmats</h2>
            <div id="deskmat-container">
              <input id="deskmat-search" type="text" placeholder="Search..." onChange={handleDeskMatSearch} onKeyUp={handleDeskMatSearch}/>
            </div>
          </div>
          <div className="modal-close-container">
            <FaTimes className="FaTimes" onClick={closeModal} />
          </div>
        </div>
        <motion.div
          initial={{y: '50vh'}}
          animate={{y: '0'}}
          exit={{y: '50vh'}}
          transition={{type: "spring",stiffness: 50}}
          className="deskmats-container px-1">
          {FullDeskMatsList}
        </motion.div>
      </Modal>
    </div>
  </div>
   )
}