export const giveawayData = [
   {
      "id": "keytok-giveaway",
      "title": "Keytok Back In The Game - Ends 12/12",
      "className": "", 
      "url": "https://gleam.io/wkFrf/sulce-keytok-back-in-the-game-keycaps-giveaway",
      "start": "2024-12-01T00:00:00-08:00",
      "end": "2024-12-12T23:59:00-08:00"
   },
   {
      "id": "leobog-k21-giveaway",
      "title": "LEOBOG K21 - Ends 12/12",
      "className": "", 
      "url": "https://gleam.io/pYhws/sulce-leobog-k21-giveaway",
      "start": "2024-12-01T00:00:00-08:00",
      "end": "2024-12-12T23:59:00-08:00"
   },
   {
      "id": "maono-g1-neo-giveaway",
      "title": "Maono G1 Neo - Ends 12/12",
      "className": "", 
      "url": "https://gleam.io/9XI6o/sulce-maono-g1-neo-giveaway",
      "start": "2024-12-01T00:00:00-08:00",
      "end": "2024-12-12T23:59:00-08:00"
   },
   {
      "id": "gummy-bears-giveaway",
      "title": "Gummy Bears Keycaps - Ends 12/12",
      "className": "", 
      "url": "https://gleam.io/ksz5b/sulce-gummy-bears-keycaps-giveaway",
      "start": "2024-12-01T00:00:00-08:00",
      "end": "2024-12-12T23:59:00-08:00"
   },
   {
      "id": "gamakay-tk75-se-giveaway",
      "title": "Gamakay TK75 SE - Ends 12/12",
      "className": "", 
      "url": "https://gleam.io/p2ZT3/sulce-gamakay-tk75-se-giveaway",
      "start": "2024-12-01T00:00:00-08:00",
      "end": "2024-12-12T23:59:00-08:00"
   },
]