import {React} from 'react'

import '../styles/Giveaways.scss';
import '../styles/MainBody.scss';
import { GiveawayCard } from './index.js';

export const Giveaways = ({giveawayData}) => {
   let GiveawayFinal = []
   let returnTrue = false
   
   giveawayData.forEach((element, id) => {
      let isOpen = false;

      let startDate = new Date(element.start);
      let endDate = new Date(element.end);
      let currentDate = new Date()

      if(currentDate > startDate && currentDate < endDate) {
         isOpen = true
      }
      if(isOpen) {
         GiveawayFinal.push(<GiveawayCard
                key={element.id} 
                id={element.id} 
                title={element.title}
                className={element.className}
                url={element.url}
              />)
         returnTrue = true
      }
    });
   return (
      <>{(returnTrue) 
         ?  <div id="giveaways">
               <div className='d-flex flex-column giveaway'>
                  <h3>Giveaways</h3>
                  <div className='giveaway-container'>
                     {GiveawayFinal}
                  </div>
               </div>
            </div>
         : <></>
      }</>
      
  )
}

// export default Giveaways