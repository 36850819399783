import {React, useState} from 'react'
import Modal from 'react-modal';
import { motion } from "framer-motion"
import { MyProductCard } from './index.js';
import { FaArrowRight, FaTimes } from "react-icons/fa";

import $ from 'jquery';
import '../styles/Products.scss';

export const MyProducts = ({myProductsData}) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [prevProductFilter, setPrevProductFilter] = useState("all-products");
  const [searchFilter, setSearchFilter] = useState('all')

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: 'var(--black)'
    },
  };

  let shortListMaxCount = 6
  let productSearchCount = 0; 
  let shortProductSearchCount = 0; 
  let shortProductsData = []

  let tempProductsData = myProductsData;
  tempProductsData.forEach(element => {
    if(!element.type.toLowerCase().includes('highlight') && !element.type.toLowerCase().includes('highlights')) {
      shortProductsData.push(element);
    } 
  })


  if ($(window).width() < 780) {
    shortListMaxCount = 6;
    shortProductsData = shortProductsData.splice(0, shortListMaxCount);
  }
  else {
    shortListMaxCount = 8;
    shortProductsData = shortProductsData.splice(0, shortListMaxCount);
  }

   
   const HighlightProductsList = myProductsData.map((element, id) => {
    if(element.type.toLowerCase().includes('highlight')) {
      return <MyProductCard
        key={element.id}
        id={element.id}
        title={element.title}
        desc={element.desc}
        className={element.className}
        img={element.img} 
        url={element.url}
        videoPreview={element.preview}
      />
    } else {
      return <></>
    }
  })

    const ShortProductsList = shortProductsData.map((element, id) => {
      return <MyProductCard
        key={element.id}
        id={element.id}
        title={element.title}
        desc={element.desc}
        className={element.className}
        img={element.img} 
        url={element.url}
        videoPreview={element.preview}
      />
    })

    const SetupProductsList = myProductsData.map((element, id) => {
      if(element.type.toLowerCase().includes('setup')) {
        return <MyProductCard
          key={element.id}
          id={element.id}
          title={element.title}
          desc={element.desc}
          className={'m-0'}
          img={element.img} 
          url={element.url}
          videoPreview={element.preview}
         />
      } else {
        return <></>
      }
    })

    const SoftwareProductsList = myProductsData.map((element, id) => {
      if(element.type.toLowerCase().includes('software') || element.type.toLowerCase().includes('softwares')) {
        return <MyProductCard
            key={element.id}
            id={element.id}
            title={element.title}
            desc={element.desc}
            className={'m-0'}
            img={element.img} 
            url={element.url}
            videoPreview={element.preview}
         />
      } else {
        return <></>
      }
    })
    const OtherProductsList = myProductsData.map((element, id) => {
      if(element.type.toLowerCase().includes('other')) {
        return <MyProductCard
            key={element.id}
            id={element.id}
            title={element.title}
            desc={element.desc}
            className={'m-0'}
            img={element.img} 
            url={element.url}
            videoPreview={element.preview}
         />
      } else {
        return <></>
      }
    })



   let AllProductsList = myProductsData.map((element, id) => {
    return <MyProductCard
          key={element.id}
          id={element.id}
          title={element.title}
          desc={element.desc}
          className={'m-0'}
          img={element.img} 
          url={element.url}
          videoPreview={element.preview}
      />
  })
  const [StaticAllProductsList] = useState(AllProductsList)
  
  function openModal() {
    setIsOpen(true);
    setProductsShown(StaticAllProductsList)

    window.setTimeout(function(){
      $('.loading-container')
        .addClass('opacity-0')
        .removeClass('opacity-1')
    }, 500)

    window.setTimeout(function(){
      $('.loading-container')
        .addClass('d-none')
        .removeClass('d-flex')
    }, 1000)
  }


  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // console.log("modal is open")
  }

  function closeModal() {
    setIsOpen(false);
    $('.loading-container')
      .addClass('d-flex')
      .removeClass('d-none')
      .addClass('opacity-1')
      .removeClass('opacity-0')
  }


  let finalProductsSearchList = []
  const handleProductSearch = (e) => {
    productSearchCount = 0
    myProductsData.forEach((element, id) => {
      if(element.type.toLowerCase().includes(searchFilter) 
      && (element.title.toLowerCase().includes(e.target.value.toLowerCase())
      || element.desc.toLowerCase().includes(e.target.value.toLowerCase()))
      ) {
        productSearchCount++;
        finalProductsSearchList.push(<MyProductCard
          key={element.id}
          id={element.id}
          title={element.title}
          desc={element.desc}
          className={'m-0'}
          img={element.img} 
          url={element.url}
          videoPreview={element.preview}
        />)
      }
    })

    if(productSearchCount === 0) {
        finalProductsSearchList = <div className="d-flex mx-2"><p>No Results Found</p></div>
    }

    setProductsShown(finalProductsSearchList)
   
  }

  const handleShortProductSearch = (e) => {
    shortProductSearchCount = 0
    let finalShortProductsSearchList = []
    myProductsData.forEach((element, id) => {
      if((element.type.toLowerCase().includes(searchFilter) 
      && (element.title.toLowerCase().includes(e.target.value.toLowerCase())
      || element.desc.toLowerCase().includes(e.target.value.toLowerCase())))
      && !element.type.toLowerCase().includes('highlight')
      ) {
        shortProductSearchCount++;
        finalShortProductsSearchList.push(<MyProductCard
            key={element.id}
            id={element.id}
            title={element.title}
            desc={element.desc}
            className={element.className}
            img={element.img} 
            url={element.url}
            videoPreview={element.preview}
          />)
        }
    })

    if(shortProductSearchCount === 0) {
      finalShortProductsSearchList = <div className="d-flex mx-2"><p>No Results Found</p></div>
    } else {
      finalShortProductsSearchList = finalShortProductsSearchList.slice(0, shortListMaxCount)
    }

    setShortProductsShown(finalShortProductsSearchList)
  }

  const changeProductFilter = (e) => {
    if(e.target.id !== prevProductFilter) {
      $(`#${e.target.id}`).addClass('product-filter-selected')
      $(`#${prevProductFilter}`).removeClass('product-filter-selected')
      setPrevProductFilter(e.target.id)

      if(e.target.id === "all-products") {
        setProductsShown(AllProductsList)
        setSearchFilter("all")
      } else if (e.target.id === "setup-products") {
          setProductsShown(SetupProductsList)
          setSearchFilter("setup")
      } else if (e.target.id === "software-products") {
        setProductsShown(SoftwareProductsList)
        setSearchFilter("software")
      } else if (e.target.id === "other-products") {
        setProductsShown(OtherProductsList)
        setSearchFilter("other")
      }

      $('#product-search').val('')
    }
  }

  const [productsShown, setProductsShown] = useState(AllProductsList)
  const [shortProductsShown, setShortProductsShown] = useState(ShortProductsList)

  return (
    <div id="products">
      {/* <h3>Highlight</h3>
      <div className="pb-0 products-container">
        {HighlightProductsList}
      </div> */}

      <div id="short-product-container">
        <h3 className='mt-1'>My Products</h3>
        {/* <div className="product-container">
          <input id="short-product-search" type="text" placeholder="Search..." onChange={handleShortProductSearch} />
        </div> */}
      </div>
      <div className="pb-0 products-container">
        {shortProductsShown}
        <div></div>
      </div>
      <div className="w-100 mt-3 px-2 d-flex justify-content-end">
          <div className="open-modal-text" onClick={openModal}>
            View All Products  <FaArrowRight id="FaArrowRight"/>
          </div>
        </div>
        
      <Modal
        id='products-modal'
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Full Products List Modal"
        ariaHideApp={false}
      >
        <div className='opacity-1 loading-container d-flex'>
          <div className="lds-ripple"><div></div><div></div></div>
        </div>
        <div className="product-modal-header">
          <div id="product-header-container">
            <div id="product-search-container" className='d-flex flex-row w-75'>
              <h2>All Products</h2>
              <div className="product-container">
                <input id="product-search" type="text" placeholder="Search..." onChange={handleProductSearch} />
              </div>
            </div>
            <div className="modal-close-container">
              <FaTimes className="FaTimes" onClick={closeModal} />
            </div>
          </div>
          <div id="products-filters">
            <ul>
              <li id="all-products" className='product-filter-selected' onClick={changeProductFilter}>All</li>
              <li id="setup-products" onClick={changeProductFilter}>Setup</li>
              <li id="software-products" onClick={changeProductFilter}>Software</li>
              <li id="other-products" onClick={changeProductFilter}>Other</li>
            </ul>
          </div>
        </div>
        <motion.div 
          //animate
          initial={{y: '50vh'}}
          animate={{y: '0'}}
          exit={{y: '50vh'}}
          transition={{type: "spring",stiffness: 25}}

          className="px-2 modal-products-container"
        >
          {productsShown}
        </motion.div>
      </Modal>
    </div>
   )
}

// export default Products